import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'

import mainHeader from '../../../images/solutions/rpa/hiDev.jpg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { FaqSection } from '../../../components/faq'
import { rpaList } from '../../../utils/customersList'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FaChevronRight } from 'react-icons/fa'
import BreadcrumbComponent from '../../../components/breadcrumb'
import { StaticImage } from 'gatsby-plugin-image'

const faqItems = [
  {
    question: 'O que é necessário para iniciar a implementação do RPA?',
    answer:
      'Para iniciar um projeto de automação, é necessária uma licença de software RPA, um dispositivo e as permissões de usuário dos sistemas que o robô irá operar. Além disso, é preciso atenção para treiná-lo, monitoramento da sua execução e paciência, assim como durante a contratação de um novo colaborador.',
  },
  {
    question: 'Quem participa na implementação do RPA?',
    answer:
      'Devem participar os usuários-chave de cada processo a ser robotizado. Cada usuário-chave deverá ser capaz de mostrar o fluxo do processo, contemplando entradas e saídas, regras de negócio, interações entre sistemas, exceções e volume de trabalho. Já a equipe de T.I, deve atuar de forma complementar, fornecendo acessos e infraestrutura adequada para o desenvolvimento e homologação dos bots.',
  },
  {
    question: 'Qual é o prazo médio de implementação de um robô (RPA)?',
    answer:
      'O prazo de implementação de um robô (RPA) varia de acordo com o seu nível de complexidade. Na High Jump, classificamos as automações em baixa, média e alta complexidade. A seguir, os prazos estimados conforme a complexidade: baixa - 30 dias; média - 45 dias e alta - 60 dias. Os prazos são contados a partir da liberação dos acessos aos sistemas e infraestrutura necessária para iniciar a programação.',
  },
  {
    question: 'Quais são os fatores de sucesso para uma boa implementação RPA?',
    answer:
      'Um dos principais fatores de sucesso é a atribuição de um Key User Global para o projeto de automação. O papel do Key User Global é centralizar as demandas de desenvolvimento e homologação de robôs, priorizando os entregáveis e garantindo o resultado dos processos robotizados. Além disso, deve fazer a interface entre a consultoria e as áreas de negócio para evolução do projeto.',
  },
]

const DevPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location

  return (
    <Layout location={location}>
      <SEO title="High Jump - Hi-Dev" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={styles.mainTitle}>
              <h1>Hi-Dev</h1>
              <p>
                Alavanque projetos de automação robótica de processos (RPA) com
                a alocação de um squad multidisciplinar. Profissionais
                especializados desde o levantamento de requisitos à
                implementação de robôs.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent
              replacedMainPath="Automação Robótica de Processos"
              replacedLabel="Hi-Dev"
            />
            <div className={styles.hiRpaSection}>
              <div className={styles.title}>
                <h1>O que é o Hi-Dev?</h1>
                <p>
                  É uma metodologia desenvolvida pela High Jump para apoiar e
                  acelerar a implementação de soluções RPA, contemplando
                  análise, desenho, desenvolvimento, teste e homologação de
                  fluxos automatizados (robotizados).
                </p>
              </div>
              <div className={styles.content}>
                <p>
                  Após a realização do diagnóstico e mapeamento de processos,
                  encontram-se alguns desafios na fase de implementação das
                  automações.
                </p>
                <p>
                  Alguns <strong>erros comuns</strong> durante a fase de{' '}
                  <strong>desenvolvimento e homologação das automações</strong>{' '}
                  pode impactar negativamente no resultado do projeto de RPA,
                  como:
                </p>
                <ul>
                  <li>Desconsiderar limitações de sistemas legados </li>
                  <li>Criação de bots com lógica rígida </li>
                  <li>Documentação de processo deficiente </li>
                  <li>Falta de testes incrementais</li>
                  <li>Dependência excessiva de desenvolvedores internos</li>
                  <li>Testes incompletos ou mal planejados</li>
                  <li>Falta de envolvimento de usuários finais</li>
                  <li>Ausência de critérios de sucesso</li>
                </ul>
                <p>
                  Para minimizar estes erros e produzir automações eficientes,
                  alocamos uma equipe multidisciplinar e especializada, que
                  atuará como um <strong>Centro de Excelência em RPA</strong>
                  (CoE RPA), composto por Analistas de Processo (BPM),
                  Desenvolvedores e Gestores de Projeto. Fale agora com um de
                  nossos especialistas e eleve seu projeto de automação ao
                  próximo nível.
                </p>
                <AnchorLink to={`/automacao-robotica-de-processos/dev#contact`}>
                  Solicite mais informações
                  <FaChevronRight />
                </AnchorLink>
              </div>
            </div>

            <div className={styles.hiRpaSection} style={{ marginTop: '3rem' }}>
              <div className={styles.title}>
                <h1>Confira cada etapa da metodologia de trabalho:</h1>
              </div>

              <div className={styles.processContainer}>
                <div className={styles.cardsContainer}>
                  <div className={styles.card}>
                    <span className={styles.label}>1ª etapa - Análise</span>
                    <p>
                      <strong>Coleta de dados</strong> do processo para
                      automação, incluindo requisitos funcionais e técnicos.
                    </p>
                    <p>
                      Entradas e saídas, regras de negócio, interações,
                      exceções.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>
                      2ª etapa - Desenvolvimento
                    </span>
                    <p>
                      <strong>Programação</strong> dos scripts, integração com
                      softwares e preparação para testes.
                    </p>
                    <p>
                      Abrange a lógica, interações, regras de negócio, erros e
                      exceções, log e monitoramento.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>3ª etapa - Homologação</span>
                    <p>
                      <strong>Planejamento</strong> e execução dos testes
                      funcionais, de performance e de exceção. Ajustes e
                      otimização.
                    </p>
                    <p>
                      Requer validação formal com os usuários finais (key
                      users).
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>Entrega</span>
                    <p>
                      <strong>Bot em produção</strong>, pronto para alavancar a
                      produtividade da equipe.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.hiRpaSection}
              style={{ marginTop: '3rem', marginBottom: '3rem' }}
            >
              <div className={styles.title}>
                <h1>O que será entregue ao final do processo?</h1>
              </div>
              <div className={styles.contentMid}>
                <div className={styles.text}>
                  <p>
                    Ao final, serão entregues os robôs (RPA) prontos para a
                    implementação em ambiente de produção, garantindo que tragam
                    os benefícios esperados, como redução de custos e aumento da
                    produtividade da equipe.
                  </p>
                  <p>
                    Através desta metodologia, é possível alavancar projetos de
                    automação, otimizando os recursos alocados, aumentando a
                    confiança no projeto e a percepção de valor em todos os
                    níveis da companhia.
                  </p>
                </div>
                <div className={styles.image}>
                  <StaticImage
                    src="../../../images/solutions/rpa/devResult.png"
                    alt="Conclusao do diagnostico"
                    title="Conclusao do diagnostico"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={rpaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Consultoria para otimização de processos</h2>
                  <p>Evitando que erros ou redundâncias sejam automatizados</p>
                </div>
                <div className={styles.div2}>
                  <h2>Treinamento prático para as áreas de negócio</h2>
                  <p>Com o conteúdo preparatório para implementação do RPA</p>
                </div>
                <div className={styles.div3}>
                  <h2>Estudo de viabilidade de projeto</h2>
                  <p>
                    Contemplando custo, retorno sobre o investimento e payback
                  </p>
                </div>
                <div className={styles.div4}>
                  <h2>Mão de obra especializada e exclusiva</h2>
                  <p>
                    Alocação de profissionais certificados e dedicação exclusiva
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />

          <FaqSection items={faqItems} />

          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default DevPage
